/*
 * @Descripttion: 正则校验
 * @FilePath: /azg/src/utils/pattern.js
 * @Author: 张兴业
 * @Date: 2020-12-10 00:42:01
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-04-12 22:46:29
 */

//校验手机号
export function verifyPhone(phone, prefix = "+86") {
  //交由后端进行验证
  if (prefix === "+86") {
    const reg = /^(1[3456789])\d{9}$/;
    if (reg.test(phone)) {
      return true;
    }
    return false;
  }
  if (prefix === "+61") {
    const reg = /^(04|4)\d{8}$/;
    if (reg.test(phone)) {
      return true;
    }
    return false;
  }
  if (prefix === "+64") {
    const reg = /^(02|2)\d{8,10}$/;
    if (reg.test(phone)) {
      return true;
    }
    return false;
  }
  return false;
}

//校验手机号(无区号)
export function verifyPhoneWithNoAreaCode(phone) {
  //交由后端进行验证
  const regCN = /^(1[3456789])\d{9}$/;
  const regAU = /^(04|4)\d{8}$/;
  const regNZL = /^(02|2)\d{8,10}$/;
  if (regCN.test(phone) || regAU.test(phone) || regNZL.test(phone)) {
    return true;
  }
  return false;
}

// 校验密码
export function verifyPasswd(passwd) {
  // const reg = /^[A-Za-z][A-Za-z0-9]{5,11}$/; // 6-12位以字母开头
  const reg = /^[A-Za-z0-9]{6,12}$/; // 6-12位数字或字母
  if (reg.test(passwd)) {
    return true;
  }
  return false;
}
// 校验邮箱
export function verifyEmail(email) {
  // const reg = /^[A-Za-z][A-Za-z0-9]{5,11}$/; // 6-12位以字母开头
  const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/; // 6-12位数字或字母
  if (reg.test(email)) {
    return true;
  }
  return false;
}
// 判断是否移动端
export function isMobileTerminal() {
  const reg = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;
  if (navigator.userAgent.match(reg)) {
    return true;
  }
  return false;
}

// 判断是否是iOS/Pad OS设备
export function isIPhoneOrIPad() {
  const reg = /(phone|pad|pod|iPhone|iPod|ios|iPad)/i;
  if (navigator.userAgent.match(reg)) {
    return true;
  }
  return false;
}

// 判断是否是Android设备
export function isAndroid() {
  const reg = /(Android|android)/i;
  if (navigator.userAgent.match(reg)) {
    return true;
  }
  return false;
}

export function isWxClient() {
  // var ua = navigator.userAgent.toLowerCase();
  // console.log(ua.match(/MicroMessenger/i))
  // if (ua.match(/MicroMessenger/i) === "micromessenger") {
  //   return true;
  // }
  // return false;
  var ua = navigator.userAgent.toLowerCase();
  var isWeixin = ua.indexOf("micromessenger") !== -1;
  return isWeixin;
}
